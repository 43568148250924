import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import RichText from './RichText';
const StyledButton = styled(Button)`
  background-color: ${props => props.theme.brandDark};
  color: ${props => props.theme.neutralLight};

  &:hover {
    background-color: ${props => props.theme.inputBtnFocusColor};
    color: ${props => props.theme.neutralLight};
  }
`;
const StyledLabel = styled(Label)`
  &:hover {
    color: ${props => props.theme.inputBtnFocusColor};
    cursor: pointer;
  }
`;
const StyledInput = styled(Input)`
  &:checked {
    background-color: ${props => props.theme.brandDark};
    border-color: ${props => props.theme.brandDark};
  }
`;
const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
`;
const StyledModalHeader = styled(ModalHeader)`
  border: none;
`;
const StyledModalFooter = styled(ModalFooter)`
  border: none;
`;
interface IntroModalProps {
  size?: string;
  title: string;
  paragraph: string;
}
const IntroModal = ({
  size = 'lg',
  title,
  paragraph
}: IntroModalProps) => {
  const {
    t
  } = useTranslation();
  const [enabled, setEnabled] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  useEffect(() => {
    const showModal = localStorage.getItem('show-intro-modal');
    if (showModal === null || JSON.parse(showModal) === true) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, []);
  const handleClose = () => setEnabled(false);
  const handleClickClose = () => {
    localStorage.setItem('show-intro-modal', JSON.stringify(!isChecked));
    handleClose();
  };
  const handleChangeCheckbox = e => {
    setIsChecked(e.target.checked);
  };
  return <div data-sentry-component="IntroModal" data-sentry-source-file="IntroModal.tsx">
      <Modal isOpen={enabled} toggle={handleClose} size={size} fade={true} data-sentry-element="Modal" data-sentry-source-file="IntroModal.tsx">
        <StyledModalHeader toggle={handleClose} data-sentry-element="StyledModalHeader" data-sentry-source-file="IntroModal.tsx">
          <RichText html={title} data-sentry-element="RichText" data-sentry-source-file="IntroModal.tsx" />
        </StyledModalHeader>
        <ModalBody data-sentry-element="ModalBody" data-sentry-source-file="IntroModal.tsx">
          <RichText html={paragraph} data-sentry-element="RichText" data-sentry-source-file="IntroModal.tsx" />
        </ModalBody>
        <StyledModalFooter data-sentry-element="StyledModalFooter" data-sentry-source-file="IntroModal.tsx">
          <StyledContainer data-sentry-element="StyledContainer" data-sentry-source-file="IntroModal.tsx">
            <FormGroup check data-sentry-element="FormGroup" data-sentry-source-file="IntroModal.tsx">
              <StyledLabel check data-sentry-element="StyledLabel" data-sentry-source-file="IntroModal.tsx">
                <StyledInput type="checkbox" checked={isChecked} onChange={handleChangeCheckbox} data-sentry-element="StyledInput" data-sentry-source-file="IntroModal.tsx" />
                {t('do-not-show-again')}
              </StyledLabel>
            </FormGroup>
            <StyledButton onClick={handleClickClose} data-sentry-element="StyledButton" data-sentry-source-file="IntroModal.tsx">{t('close')}</StyledButton>
          </StyledContainer>
        </StyledModalFooter>
      </Modal>
    </div>;
};
export default IntroModal;