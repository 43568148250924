import React, { type HTMLProps } from 'react';
import SVG from 'react-inlinesvg';
import { useTheme } from 'styled-components';
import { getLogger } from '@/common/log';
import { getThemeStaticURL } from '@/common/theme';
const camelToKebabCase = (s: string) => s.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
const logger = getLogger('icon');
type IconProps = {
  name?: string;
  color?: string;
  width?: string;
  height?: string;
  className?: string;
  alt?: string;
} & Omit<HTMLProps<SVGElement>, 'ref'>;
const Icon = (props: IconProps) => {
  const theme = useTheme();
  const {
    name = 'circleOutline',
    color = 'inherit',
    width = '1em',
    height = '1em',
    className = '',
    alt,
    ...rest
  } = props;
  const kebabName = camelToKebabCase(name);
  if (!(kebabName in theme.icons)) {
    throw new Error(`Unsupported icon: ${name}`);
  }
  const iconPath = getThemeStaticURL(theme.icons[kebabName]);
  return <SVG src={iconPath} className={`icon ${className}`} onError={error => logger.error(error, `Error rendering icon ${name}`)} key={`${theme.name}-${name}`} style={{
    width,
    height
  }} aria-hidden={alt ? 'false' : 'true'} focusable={alt ? 'true' : 'false'} title={alt} data-sentry-element="SVG" data-sentry-component="Icon" data-sentry-source-file="icon.tsx" />;
};
export const CombinedIconSymbols = () => {
  const theme = useTheme();
  /* Find the correct icon file from static folder for now */
  /* TODO: Get themed icon url from API */
  const iconFileName = camelToKebabCase(theme.combinedIconsFilename);
  const icon = `${theme.iconsUrl}/${iconFileName}.svg`;
  return <SVG style={{
    display: 'none'
  }} src={icon} data-sentry-element="SVG" data-sentry-component="CombinedIconSymbols" data-sentry-source-file="icon.tsx" />;
};
export default Icon;